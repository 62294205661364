import { useQuery } from "react-query";
import { publicClient } from "../api";
import { PLAN_URL } from "../urls";

async function getPlan(planId: number | undefined) {
  if (planId) {
    const response = await publicClient.get(PLAN_URL + planId + "/");
    return {
      ...response.data,
      coverages: response.data.coverages.map((cov: any) => ({
        ...cov,
        coverage_value: parseFloat(cov.coverage_value),
      })),
      configs: response.data.configs.map((config: any) => ({
        ...config,
        value: parseFloat(config.value),
      })),
    };
  }
  return null;
}

export interface Benefit {
  name: string;
  description: string;
  icon: string;
  type: "coverage" | "assistance";
  who_can_use: "holder" | "holder_and_dependants";
  slug: string;
  coverage_value: number;
}

export interface PlanConfig {
  id: number;
  n_dependants: number;
  value: number;
  age: number;
}

export interface Plan {
  id: number;
  name: string;
  icon: string | null;
  days: number;
  family_plan: boolean;
  female_plan: boolean;
  occupation: "EM" | "SE";
  assistances: Benefit[];
  coverages: Benefit[];
  configs: PlanConfig[];
}

export function usePlan(planId: number | undefined) {
  return useQuery<Plan>(PLAN_URL + planId + "/", () => getPlan(planId), {
    enabled: planId != undefined,
  });
}
