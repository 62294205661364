// TODO get these values from backend

export const SEGURIZE_LIFE_PRICE = 19.9;

export const MIN_TELEMEDICINE_DEPENDANTS = 1;
export const MAX_TELEMEDICINE_DEPENDANTS = 10;

export const PAGARME_ENCRIPTION_KEY = process.env
  .NEXT_PUBLIC_PAGARME_ENCRIPTION_KEY as string;

export const BENEFIT_WHO_CAN_USE: Record<string, string> = {
  family: "Família",
  holder: "Titular",
  dependants: "Dependentes",
};

export const BENEFIT_STATUS: Record<string, string> = {
  active: "Ativo",
  error: "Inativo",
  inactive: "Inativo",
  pending: "Em implantação",
  processing: "Em implantação",
};

export const BENEFIT_STATUS_DESCRIPTION: Record<string, string> = {
  active: "Você já pode contar com esse benefício",
  error: "Você não tem acesso a esse benefício",
  inactive: "Você não possui mais esse benefício",
  pending: "Em breve você poderá contar com essa cobertura",
  processing: "Em breve você poderá contar com essa cobertura",
};

export const SEX_OPTIONS: Record<string, string> = {
  F: "Feminino",
  M: "Masculino",
};

export const scrollIntoView = (ref: any) => {
  if (ref?.current) return ref.current.scrollIntoView({ behavior: "smooth" });
};

export const occupationList = [
  {
    label: "Autônomo - Trabalho por conta",
    value: "SE",
  },
  {
    label: "CLT - Carteira assinada",
    value: "EM",
  },
];

export const maritalStatusList = [
  {
    label: "Solteiro(a)",
    value: "S",
  },
  {
    label: "Casado(a)",
    value: "M",
  },
  {
    label: "Viúvo(a)",
    value: "W",
  },
  {
    label: "Desquitado(a)",
    value: "P",
  },
  {
    label: "Divorciado(a)",
    value: "D",
  },
];

export const SUBSCRIPTION_STATUS_MAP: Record<string, string> = {
  paid: "pago",
  canceled: "cancelado",
  unpaid: "não pago",
  pending_payment: "pagamento pendente",
  trialing: "em avaliação",
  rejected: "rejeitado",
};

export const USE_SOCIAL_SUBSCRIPTION =
  process.env.NEXT_PUBLIC_USE_SOCIAL_SUBSCRIPTION === "true";

export const SIGNUP_URL = USE_SOCIAL_SUBSCRIPTION ? "sign-up" : "sign-up-mail";
