import { AxiosInstance } from "axios";
import { useQuery } from "react-query";
import { useAuth } from "../authContext";
import { PROFILE_URL } from "../urls";
import { PlanConfig } from "./usePlan";

const getProfile = (client: AxiosInstance) => async () => {
  const response = await client.get(PROFILE_URL);
  return response.data;
};

export function useUser() {
  const { privateClient, isAuthenticated } = useAuth();
  const profileQuery = useQuery(PROFILE_URL, getProfile(privateClient!), {
    enabled: isAuthenticated,
  });
  return profileQuery;
}
export interface BenefitInterface {
  coverage_value: string;
  status: "active" | "error" | "inactive" | "pending" | "processing";
  name: string;
  description: string;
  icon: string;
  type: string;
  who_can_use: string;
  slug:
    | "death"
    | "accidental-death"
    | "accidental-disability"
    | "cancer-diagnosis"
    | "daily-hospitalization"
    | "temporary-disability"
    | "income-loss"
    | "spouse-death"
    | "family-funeral"
    | "home-assistance"
    | "family-telemedicine"
    | "individual-telemedicine"
    | "lottery"
    | "psycho-assistance"
    | "nutri-assistance";
}

export interface SubscriptionInterface {
  id: number;
  status: string;
  created_at: string;
  first_period_start: string;
  current_period_start: string;
  current_period_end: string;
  plan_config: PlanConfig;
  external_id: number;
  external_url: string;
  card_last_digits: string;
  card_brand: string;
  current_boleto_url: string;
  coverages: BenefitInterface[];
  assistances: BenefitInterface[];
}

export interface UserInterface {
  id: number;
  email: string;
  date_joined: string;
  last_login: string;
  has_usable_password: boolean;
}

export interface ProfileInterface {
  user: UserInterface;
  full_name: string;
  document_number: string;
  birth_date: string;
  sex: string;
  phone: string;
  subscription: SubscriptionInterface;
}
