export const GTM_ID = "GTM-M3BF4NV";

declare global {
  interface Window {
    dataLayer: {
      push: (data: any) => void;
    };
  }
}

// log access on each page
export const pageview = (url: string) => {
  window.dataLayer.push({
    event: "pageview",
    page: url,
  });
};

// when a login is performed - not the attempt but actually the login performed
export const loginEvent = (method: string) => {
  window.dataLayer.push({
    event: "login",
    method: method, // facebook, apple, google, password
  });
};

// when a user signs up - new hire flow
export const signupEvent = (method: string) => {
  window.dataLayer.push({
    event: "sign_up",
    method: method, // facebook, apple, google, password
  });
};

// click forward to personal data step
export const checkoutPersonalInfoEvent = () => {
  window.dataLayer.push({
    event: "personal_info_checkout",
  });
};

// click forward from address data step
export const checkoutAdressInfoEvent = () => {
  window.dataLayer.push({
    event: "address_info_checkout",
  });
};

// step to add dependants
export const checkoutAddDependantsEvent = (
  action: string,
  benefit: string,
  n_dependants: number
) => {
  window.dataLayer.push({
    event: "add_dependants_checkout",
    action: action,
    benefit: benefit,
    n_dependants: n_dependants,
  });
};

// when the user has a problem in payment and tries again
export const checkoutRetryPaymentEvent = () => {
  window.dataLayer.push({
    event: "step_retry_payment_checkout",
  });
};

// payment step - new hire or plan upgrade flow
export const paymentEvent = (
  coupon: string,
  currency: string,
  items: Array<object>,
  payment_type: string,
  value: number
) => {
  window.dataLayer.push({
    event: "add_payment_info",
    coupon: coupon,
    currency: currency,
    items: items, // plans id
    paymentType: payment_type,
    value: value,
  });
};

// when the user starts the purchase process - entry into the hiring or upgrade flow
export const beginCheckoutEvent = (
  coupon: string,
  currency: string,
  items: Array<object>,
  value: number
) => {
  window.dataLayer.push({
    event: "begin_checkout",
    coupon: coupon,
    currency: currency,
    items: items, // plans id
    value: value,
  });
};

// click on call sessions to be an indicator
export const beAnReferrerEvent = () => {
  window.dataLayer.push({
    event: "be_an_referrer",
  });
};

// successful purchase - payment approved or bank slip issued
export const purchaseEvent = (
  affiliation: string,
  coupon: string,
  currency: string,
  items: Array<object>,
  transaction_id: number,
  value: number
) => {
  window.dataLayer.push({
    event: "purchase",
    affiliation: affiliation, // referrer
    coupon: coupon,
    currency: currency,
    items: items, // plans id
    transactionId: transaction_id,
    value: value,
  });
};
