import AlertMessage from "@components/Alerts";
import GoogleTagManager from "@components/GoogleTagManager";
import PrivateRoute from "@components/PrivateRoute";
import CheckSubscriptionStatus from "@components/CheckSubscriptionStatus";
import { AuthProvider } from "@lib/authContext";
import { SubscriptionProvider } from "@lib/subscriptionContext";
import "@styles/globals.scss";
import React, { ReactNode } from "react";
import "react-credit-cards/lib/styles.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";

// React Query Client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30 * 1000, // 30s
      refetchOnWindowFocus: false,
    },
  },
});
const privateRoutes = [
  /\/dashboard*/,
  /\/subscription\/subscription-processing/,
];

interface LayoutProps {
  children: ReactNode;
}

function MyApp({ Component, pageProps }: any) {
  const Layout: React.FC<LayoutProps> =
    Component.layout || (({ children }) => <>{children}</>);

  return (
    <GoogleTagManager>
      <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID!}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <SubscriptionProvider>
              <PrivateRoute routes={privateRoutes}>
                <CheckSubscriptionStatus routes={privateRoutes}>
                  <Layout>
                    <Component {...pageProps} />
                    <AlertMessage />
                  </Layout>
                </CheckSubscriptionStatus>
              </PrivateRoute>
            </SubscriptionProvider>
          </AuthProvider>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </GoogleTagManager>
  );
}

export default MyApp;
