import React, { ReactNode, useEffect } from "react";
import { useRouter } from "next/router";
import * as gtm from "../../lib/gtm";
import Script from "next/script";

interface GoogleTagManagerProps {
  children: ReactNode;
}

const GoogleTagManager: React.FC<GoogleTagManagerProps> = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    router.events.on("routeChangeComplete", gtm.pageview);
    return () => {
      router.events.off("routeChangeComplete", gtm.pageview);
    };
  }, [router.events]);

  return (
    <>
      {/* Google Tag Manager - Global base code */}
      <Script
        id="gtag-script"
        dangerouslySetInnerHTML={{
          __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', '${gtm.GTM_ID}');
              `,
        }}
      />
      {children}
    </>
  );
};

export default GoogleTagManager;
