import { AxiosInstance } from "axios";
import { useRouter } from "next/router";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import FullPageLoader from "../components/FullPageLoader";
import { createPrivateAPIInstance, publicClient } from "./api";
import { LOGIN_URL, LOGOUT_URL, REFRESH_TOKEN_URL } from "./urls";

interface Token {
  access: string;
  refresh: string;
}
let token: Token | null = null;

interface AuthProviderProps {
  children: ReactNode;
}

export const getToken = () => token;
export const setToken = (newToken: Token | null) => {
  token = newToken;
};

export const AuthContext = createContext({
  isAuthenticated: false,
  isLoading: false,
  login: async (data: { email: string; password: string }) => {},
  logout: async () => {},
  tokenLogin: (newToken: Token) => {},
  privateClient: null as null | AxiosInstance,
});

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const router = useRouter();
  const [isAuthenticated, setIsAuthenticated] = useState(token != null);
  const [isLoading, setIsLoading] = useState(token == null);

  const login = async (data: { email: string; password: string }) => {
    let response = await publicClient.post(LOGIN_URL, data, {
      withCredentials: true,
    });
    setToken(response.data);
    setIsAuthenticated(true);
    setRefreshTokenDefined("true");
  };

  const tokenLogin = (newToken: Token) => {
    setToken(newToken);
    setIsAuthenticated(true);
    setRefreshTokenDefined("true");
  };

  const logout = async () => {
    token = null;
    await publicClient.post(LOGOUT_URL, {}, { withCredentials: true });
    setIsAuthenticated(false);
    setRefreshTokenDefined("false");
    router.push("/login");
  };

  const refreshToken = useCallback(async () => {
    const Cookies = (await import("js-cookie")).default;

    if (Cookies.get("refreshTokenDefined") == "true") {
      try {
        let response = await publicClient.post(
          REFRESH_TOKEN_URL,
          {},
          { withCredentials: true }
        );
        token = response.data;
        if (!isAuthenticated) {
          setIsAuthenticated(true);
          setRefreshTokenDefined("true");
        }
      } catch (e) {
        if (isAuthenticated) {
          setIsAuthenticated(false);
          setRefreshTokenDefined("false");
        }
      }
    } else if (isAuthenticated) {
      setIsAuthenticated(false);
    }
  }, [isAuthenticated]);

  const setRefreshTokenDefined = async (value: string) => {
    const Cookies = (await import("js-cookie")).default;
    Cookies.set("refreshTokenDefined", value);
  };

  useEffect(() => {
    if (token == null) {
      refreshToken().finally(() => {
        setIsLoading(false);
      });
    }
  }, [isAuthenticated, refreshToken]);

  const privateClient = createPrivateAPIInstance(refreshToken);

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        login,
        logout,
        tokenLogin,
        privateClient,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
