import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertCloseBtn from "./AlertCloseBtn";

const AlertMessage = () => {
  return (
    <ToastContainer
      autoClose={4000}
      closeButton={AlertCloseBtn}
      closeOnClick={false}
      hideProgressBar
      newestOnTop
      position="bottom-center"
    />
  );
};

export default AlertMessage;
