import React, { Fragment, ReactNode, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useAuth } from "@lib/authContext";
import FullPageLoader from "../FullPageLoader";
import { useUser } from "@lib/hooks/useUser";

interface PrivateRouteProps {
  routes: Array<RegExp>;
  children: ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ routes, children }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const router = useRouter();

  const pathIsProtected =
    routes.filter((rx) => rx.test(router.pathname)).length > 0;

  useEffect(() => {
    if (!isLoading && !isAuthenticated && pathIsProtected) {
      router.push({
        pathname: "/login",
        query: { nextURL: router.asPath },
      });
    }
  }, [router, isLoading, isAuthenticated, pathIsProtected]);

  if ((isLoading || !isAuthenticated) && pathIsProtected) {
    return <FullPageLoader />;
  }

  return <Fragment>{children}</Fragment>;
};

export default PrivateRoute;
