import FullPageLoader from "@components/FullPageLoader";
import { useUser } from "@lib/hooks/useUser";
import { useRouter } from "next/router";
import { ReactNode, useEffect } from "react";

interface CheckSubscriptionStatusProps {
  routes: Array<RegExp>;
  children: ReactNode;
}

const CheckSubscriptionStatus: React.FC<CheckSubscriptionStatusProps> = ({
  children,
  routes,
}) => {
  const { isLoading, data: user, failureCount } = useUser();
  const router = useRouter();
  const inactiveSubscriptionUrl = "/dashboard/inactive-subscription";

  const isPathProtected =
    routes.filter((rx) => rx.test(router.pathname)).length > 0;

  useEffect(() => {
    if (
      user != null &&
      user.subscription == null &&
      isPathProtected &&
      router.pathname != inactiveSubscriptionUrl
    ) {
      router.push({ pathname: inactiveSubscriptionUrl });
    } else if (
      user?.subscription != null &&
      router.pathname === inactiveSubscriptionUrl
    ) {
      router.replace({ pathname: "/dashboard" });
    }
  }, [user, isPathProtected, router]);

  return (isLoading && failureCount === 0) ||
    (user != null &&
      user.subscription == null &&
      isPathProtected &&
      router.pathname != inactiveSubscriptionUrl) ? (
    <FullPageLoader />
  ) : (
    <>{children}</>
  );
};

export default CheckSubscriptionStatus;
