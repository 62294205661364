import Lottie from "react-lottie-player";
import styles from "./loading.module.scss";

interface LoadingDotsProps {
  color?: boolean;
  height?: number | string;
  width?: number | string;
}

export default function LoadingDots({
  color = false,
  height = 100,
  width = 100,
}: LoadingDotsProps) {
  const animationData = color
    ? require("@public/lotties/loading-dots-color.json")
    : require("@public/lotties/loading-dots.json");

  return (
    <div className={styles.dots}>
      <Lottie
        style={{ height: height, width: width }}
        animationData={animationData}
        title="Carregando"
        loop
        play
      />
    </div>
  );
}
