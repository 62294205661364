import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { getToken } from "./authContext";

const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;

export function createAPIInstance() {
  return axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function createPrivateAPIInstance(
  refreshTokenLogic: () => Promise<void>
) {
  const privateClient = createAPIInstance();

  // Adds the acccess token to the request
  privateClient.interceptors.request.use((config) => {
    let token = getToken();
    if (token != null) config.headers.Authorization = `Bearer ${token.access}`;
    return config;
  });

  // Function that will be called to refresh authorization
  const refreshAuthLogic = async (failedRequest: any) => {
    if (failedRequest.response.status == 401) {
      await refreshTokenLogic();
      let token = getToken();
      if (token != null) {
        failedRequest.response.config.headers[
          "Authorization"
        ] = `Bearer ${token.access}`;
      }
    }
    return Promise.resolve();
  };

  // Instantiate the interceptor (you can chain it as it returns the axios instance)
  createAuthRefreshInterceptor(privateClient, refreshAuthLogic);
  return privateClient;
}

export const publicClient = createAPIInstance();
