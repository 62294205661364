import React from "react";
import styles from "@components/Alerts/alertCloseBtn.module.scss";

interface AlertCloseProps {
  closeToast: () => void;
}

function AlertCloseBtn({ closeToast }: AlertCloseProps) {
  return (
    <div className={styles.toastBtn} onClick={closeToast}>
      Fechar
    </div>
  );
}

export default AlertCloseBtn;
