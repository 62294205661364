export const LOGIN_URL = "/api/token/";
export const LOGOUT_URL = "/api/logout/";
export const REFRESH_TOKEN_URL = "/api/token/refresh/";
export const PROFILE_URL = "/api/profile/";

export const RESET_PASSWORD_URL = "/api/password/reset/";
export const RESET_PASSWORD_CONFIRMATION_URL = "/api/password/reset/confirm/";
export const CHANGE_PASSWORD_URL = "/api/change/password/";

export const SOCIAL_LOGIN_URL = "api/social/login/";
export const SOCIAL_CONNECTION_URL = "api/social-accounts/";

export const REFERRER_URL = "api/referrer/";

export const VALIDATE_MAIL = "api/validate-mail/";
export const VALIDATE_SOCIAL_ACCOUNT = "api/validate-social-account/";
export const VALIDATE_DOCUMENT = "api/validate-document/";

export const PERSONAL_DATA_URL = "subscription/personal-data/";
export const DEPENDANTS_URL = "api/dependents/";

export const CREATE_SUBSCRIPTION_URL = "api/create-subscription/";
export const UPDATE_SUBSCRIPTION_STATUS_URL = "api/subscription-status/update/";
export const SUBSCRIPTIONS_URL = "api/subscriptions/";

export const AVAILABLE_PLANS_URL = "api/available-plans/";
export const PLAN_URL = "api/plan/";
export const BENEFITS_URL = "api/benefits/";

export const SSO_API = "/api/sso/";
export const VALIDATE_SSO_API = "/api/sso/validate";

export const CREATE_CLAIM_URL = "api/insurance-claim/";

export const POLICY_URL = "api/policy/";
export const OCCUPATIONS_URL = "api/occupations/";
