import LoadingDots from "../LoadingDots";
import styles from "./fullPageLoader.module.scss";

const FullPageLoader = () => {
  return (
    <div
      className={styles.fullPageLoaderContainer}
      data-testid="fullPageLoader"
    >
      <LoadingDots />
    </div>
  );
};

export default FullPageLoader;
