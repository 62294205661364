import { SIGNUP_URL, occupationList } from "@lib/constants";
import Cookies from "js-cookie";
import moment from "moment";
import { useRouter } from "next/router";
import React, { createContext, ReactNode, useState } from "react";
import { publicClient } from "./api";
import { useAuth } from "./authContext";
import {
  checkoutAddDependantsEvent,
  checkoutAdressInfoEvent,
  checkoutPersonalInfoEvent,
  signupEvent,
} from "./gtm";
import { Plan, PlanConfig, usePlan } from "./hooks/usePlan";
import { CREATE_SUBSCRIPTION_URL } from "./urls";

export type EmailAccessData = {
  signup_method: "email";
  email: string;
  password: string;
};

export type SocialLoginAccessData = {
  signup_method: "google-oauth2" | "facebook" | "apple";
  social_account_token: string;
};

export type AccessData = EmailAccessData | SocialLoginAccessData;

export type AboutYouData = {
  occupation: { label: string; value: string };
  birth_date: string;
  weight: string;
  height: string;
};

export type PersonalData = {
  name: string;
  sex: string;
  marital_status: string;
  document_number: string;
  cellphone: string;
  accept_terms: boolean;
  agreed_share_info: boolean;
};

export type AddressData = {
  postal_code: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
};

export type CustomizationData = {
  num_dependants: number;
};

export type PaymentData = {
  payment_method: string;
  card_number: string;
  card_holder_name: string;
  card_expiration_date: string;
  card_cvv: string;
};

const SubscriptionContext = createContext({
  product: {
    name: "",
    price: 0.0,
    logo: "",
    details: [],
  },
  currentStep: 0,
  startSubscription: (planId: number, planConfigId: number) => {},
  subscriptionStarted: false,
  goBack: () => {},
  aboutYouData: null,
  submitAboutYouData: (AboutYouData: AboutYouData) => {},
  accessData: null,
  submitAccessData: (accessData: AccessData) => {},
  personalData: null,
  submitPersonalData: (personalData: PersonalData) => {},
  addressData: null,
  submitAddressData: (data: AddressData) => {},
  customizationData: { num_dependants: 0 },
  submitCustomizationData: (data: CustomizationData) => {},
  paymentData: null,
  submitPaymentData: async (data: PaymentData) => {},
  throwPaymentError: () => {},
  planStatus: "idle",
  plan: undefined,
  planConfig: undefined,
  error: undefined,
  setError: () => {},
} as {
  currentStep: number;
  startSubscription: (planId: number, planConfigId: number) => void;
  subscriptionStarted: boolean;
  goBack: () => void;
  aboutYouData: AboutYouData | null;
  submitAboutYouData: (AboutYouData: AboutYouData) => void;
  accessData: AccessData | null;
  submitAccessData: (accessData: AccessData) => void;
  personalData: PersonalData | null;
  submitPersonalData: (personalData: PersonalData) => void;
  addressData: AddressData | null;
  submitAddressData: (data: AddressData) => void;
  customizationData: CustomizationData;
  submitCustomizationData: (data: CustomizationData) => void;
  paymentData: PaymentData | null;
  submitPaymentData: (data: PaymentData) => Promise<void>;
  throwPaymentError: () => void;
  planStatus: "idle" | "loading" | "success" | "error";
  plan: Plan | undefined;
  planConfig: PlanConfig | undefined;
  error: any;
  setError: (error: any) => void;
});

interface SubscriptionProviderProps {
  children: ReactNode;
}

export const getOccupationLabel = (value: string) => {
  return occupationList.find((o) => o.value == value)?.label.split(" ")[0];
};

export const useSubscription = () => React.useContext(SubscriptionContext);

export const SubscriptionProvider: React.FC<SubscriptionProviderProps> = ({
  children,
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const [accessData, setAccessData] = useState<AccessData | null>(null);
  const [aboutYouData, setAboutYouData] = useState<AboutYouData | null>(null);
  const [personalData, setPersonalData] = useState<PersonalData | null>(null);
  const [addressData, setAddressData] = useState<AddressData | null>(null);

  const [customizationData, setCustomizationData] = useState<CustomizationData>(
    { num_dependants: 0 }
  );

  const [paymentData, setPaymentData] = useState<PaymentData | null>(null);
  const [error, setError] = useState<any>(undefined);

  const router = useRouter();
  const auth = useAuth();

  const [subscriptionStarted, setSubscriptionStarted] = useState(false);
  const [planId, setPlanId] = useState(undefined as number | undefined);
  const [planConfigId, setPlanConfigId] = useState(
    undefined as number | undefined
  );

  const { data: plan, status: planStatus } = usePlan(planId);
  const planConfig = plan?.configs.find((c) => c.id == planConfigId);

  const selectPlanConfig = (age: number, dependants: number) => {
    const selectedConfig = plan?.configs.find(
      (c) => c.age == age && c.n_dependants == dependants
    );
    setPlanConfigId(selectedConfig?.id);
  };

  const startSubscription = (planId: number, planConfigId: number) => {
    setPlanId(planId);
    setPlanConfigId(planConfigId);
    setCurrentStep(0);
    setSubscriptionStarted(true);
    router.push("/subscription/about-you");
  };

  const submitAboutYouData = (data: AboutYouData) => {
    setAboutYouData(data);
    const age = moment().diff(data.birth_date, "years");
    selectPlanConfig(age, customizationData.num_dependants);
    setCurrentStep(currentStep + 1);
    router.push("/subscription/" + SIGNUP_URL);
  };

  const submitAccessData = (data: AccessData) => {
    setAccessData(data);
    signupEvent(data.signup_method);
    setCurrentStep(currentStep + 1);
    router.push("/subscription/personal-data");
  };

  const submitPersonalData = (data: PersonalData) => {
    setPersonalData(data);
    checkoutPersonalInfoEvent();
    setCurrentStep(currentStep + 1);
    router.push("/subscription/address");
  };

  const submitAddressData = (data: AddressData) => {
    setAddressData(data);
    checkoutAdressInfoEvent();
    setCurrentStep(currentStep + 1);
    router.push("/subscription/customize-plan");
  };

  const submitCustomizationData = async (data: CustomizationData) => {
    let eventName;
    if (data.num_dependants > 0) {
      eventName = "add";
    } else {
      eventName = "not_add";
    }

    setCustomizationData(data);
    const benefit = "telemedicina";
    checkoutAddDependantsEvent(eventName, benefit, data.num_dependants);

    setCurrentStep(currentStep + 1);
    router.push("/subscription/payment");
  };

  const submitPaymentData = async (data: PaymentData) => {
    setPaymentData(data);

    let referrer_code = Cookies.get("referrer");

    let subscriptionData: any = {
      access_data: accessData,
      personal_data: {
        ...aboutYouData,
        ...personalData,
        height: parseInt(aboutYouData!.height),
        width: parseInt(aboutYouData!.weight),
        occupation: parseInt(aboutYouData!.occupation.value),
      },
      address_data: addressData,
      payment_data: data,
      subscription_data: {
        plan_config_id: planConfigId,
      },
    };

    if (referrer_code != null) {
      subscriptionData.referrer_code = referrer_code;
    }

    const response = await publicClient.post(
      CREATE_SUBSCRIPTION_URL,
      subscriptionData,
      {
        withCredentials: true,
      }
    );
    auth.tokenLogin({
      access: response.data.access,
      refresh: response.data.refresh,
    });

    await router.push({
      pathname: "/subscription/subscription-processing",
    });
  };

  const throwPaymentError = () => {
    setPaymentData(null);
    router.push("/subscription/payment-error/");
  };

  const goBack = () => {
    router.back();
    setCurrentStep(currentStep - 1);
  };

  return (
    <SubscriptionContext.Provider
      value={{
        currentStep: currentStep,
        startSubscription,
        subscriptionStarted,
        goBack,
        aboutYouData,
        submitAboutYouData,
        accessData,
        submitAccessData,
        personalData,
        submitPersonalData,
        addressData,
        submitAddressData,
        customizationData,
        submitCustomizationData,
        paymentData,
        submitPaymentData,
        throwPaymentError,
        planStatus,
        plan,
        planConfig,
        error,
        setError,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
